.container {
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.icon {
  color: white;
}
.icon.small {
  width: 12px;
  height: 12px;
}
.icon.big {
  width: 24px;
  height: 24px;
}

.thumbUp {
  background-color: var(--green-60);
}
.warning {
  background-color: var(--yellow-60);
}
.error {
  background-color: var(--red-60);
}
.assignment {
  background-color: rgba(0, 0, 199, 0.2);
}
.dq {
  background-color: #02a39333;
}
.residency {
  background-color: #00c48c33;
}
.residency .icon {
  color: var(--green-60);
}
.quiz {
  background-color: #e91f6333;
}
.onlineSession {
  background-color: #5009e833;
}
.onlineSession .icon {
  color: var(--lime);
}
.mentorshipRecording {
  background-color: #5009e833;
}
.mentorshipRecording .icon {
  color: var(--lime);
}
.graded {
  background-color: var(--primary-40);
}
.graded .icon {
  color: var(--palette-primary-main);
}
.inProgress {
  background-color: var(--grey-20);
}
.inProgress .icon {
  color: var(--primary-50);
}
.passed {
  background-color: var(--green-40);
}
.passed .icon {
  color: var(--green-70);
}
.incomplete {
  background-color: var(--red-40);
}
.incomplete .icon {
  color: var(--red-60);
}
