.button {
  margin: 8px;
  min-width: 'auto';
  padding: 0;
  color: #67748a;
}

.button:hover {
  background-color: transparent;
}

.label {
  text-transform: 'capitalize' as 'capitalize';
}

.displayFlex {
  display: flex;
}

.communityText {
  font-weight: 400;
  color: var(--black) !important;
  font-size: 16px;
  justify-content: left;
}

.notificationsContainer {
  display: flex;
  flex-direction: column;
}

.resultsContainer {
  width: 320px;
  margin-top: 8px;
  max-height: 360px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.anchorTag {
  font-size: 16px;
  font-weight: 400;
  color: #000 !important;
  margin-top: '2px';
}

.newHeaderCount {
  color: #fff;
  background: #1455b7;
  height: 16px;
  font-size: 8px;
  border-radius: 50%;
  cursor: pointer;
  width: 16px;
  justify-content: center;
  display: flex;
  padding-top: 2px;
  margin-left: 4px;
  margin-top: 4px;
}

.communityBadgeParent {
  color: #fff;
  background: #ca5786;
  height: 16px;
  font-size: 8px;
  border-radius: 50%;
  cursor: pointer;
  width: 16px;
  justify-content: center;
  display: flex;
  padding-top: 2px;
  margin-left: 4px;
  margin-top: 4px;
}

.badgeIconBox {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  padding: 6px;
  background-color: rgba(202, 87, 134, 0.25);
  border-radius: 50%;
  color: #ca5786;
  margin-right: 16px;
}

.badgeIcon {
  width: 20px;
  height: 20px;
}

.communityNotificationPrimaryRow {
  font-size: 14px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}

.communityNotificationSecondaryRow {
  font-size: .85714286em;
  font-weight: 400;
  color: rgba(0, 0, 0, .4);
}

.communityBadgeIcon {
  width: 12px;
  height: 12px;
}

.newHeader {
  color: #fff;
  background: #e51941;
  height: 16px;
  margin-left: 4px;
  font-size: 8px;
  border-radius: 4px;
  padding: 1px;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: -8px;
}

.marginTop {
  margin-top: 20px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.badgeNotification {
  padding: 10px 14px !important;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  width: 100%;
  background-color: rgba(229, 170, 194, 0.12)
}