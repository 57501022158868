.react-tel-input .special-label {  
  color: #000000;
  left: 10px;
  top: -8px;
  font-size: 12px;
  font-family: 'lato';
}
  
.react-tel-input .special-label:has(~ input.invalid-number) {
  color: #ff647c;
}