  .dialog {
    padding: 16px;
    min-height: 400px;
    height: 92vh;
    max-height: 730px;
    border-radius: 16px;
    /* min-width: 635px; */
    max-width: 678px;
    width: 678px;
    background-color: #fafafa;
  }
  .dialogContent {
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: column;
    margin-top: auto;
    width: 328px;
    padding: 20px 0px !important;
    text-align: center;
  }

  .careerGoal {
    font-size: 20px;
    font-weight: 600;
  }
  .certificateProgramHeadLine {
    font-size: 14px;
    font-weight: 400;
    color: #212121B8;
    padding-top: 8px;
    line-height: 1.4;
  }
  .freeVsPaidWrapper {
    padding-top: 24px;
    align-self: center;
  }
  .paidSection {
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid #EF6C00;
    background-color: #ffffff;
    box-shadow: 0px 0.5px 1.75px 0px rgba(0, 0, 0, 0.04), 0px 1.85px 6.25px 0px rgba(0, 0, 0, 0.12);
    .doneIcon {
      color: #EF6C00;
      font-size: 16px;

    }
  }
  .freeSection {
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    background-color: #ffffff;
    .doneIcon {
      color: #196AE5;
      font-size: 16px;
    }
  }
  
  .professionalWrapper {
    margin: 0px 16px 8px 16px;
    position: relative;
  }
  .paidProfessionalWrapper {
    margin: 0px 16px 8px 16px;
    position: relative;
  }
  .imageWrapper {
    margin: 0px 0px 8px 0px;
    position: relative;
    background-size: cover;
    background-position: top center;
    width: 326px;
    height: 120px;
  }

  .certificateCardImg {
    display: none;
  }
  .professional {
    background-color: #EF6C00;
    color: #FFFFFF;
    text-align: center;
    padding: 4px 8px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1.25px;
    border-radius: 6px 6px 0px 0px;
  }
  .free {
    background-color: rgba(33, 33, 33, 0.04);
    color: #212121EB;
    text-align: center;
    padding: 4px 8px;
    font-size: 10px;
    letter-spacing: 1.25px;
    font-weight: 600;
    border-radius: 6px 6px 0px 0px;
  }
  .programName {
    padding-top: 8px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* min-height: 40px; */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .salaryHike {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: #EF6C00;
    padding-top: 8px;
    text-transform: uppercase;
    letter-spacing: 1.25px;
  }
  .freeHike {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: #0F4089;
    padding-top: 8px;
    text-transform: uppercase;
    letter-spacing: 1.25px;
  }
  .startingAt {
    text-align: center;
    padding-top: 10px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    .amt {
        font-size: 18px;
        margin-left: 8px;
        font-weight: 600;
    }
  }
  .submitButton {
    margin-top: 12px;
    width: 100%;
    font-size: 14px;
    text-transform: capitalize;
  }
  .divider {
    margin: 8px 16px;
    list-style: none;
    background-color: rgba(33, 33, 33, 0.06);
  }

  .programHighLightsWrapper {
    padding: 8px 16px;
  }
  .programHighLightsContents {
    display: flex;
    padding-top: 8px;
  }
  .freeHighLightsContents {
    display: flex;
    padding-top: 8px;
  }
  .programHighLights{
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.92);
    padding-left: 8px;
    align-self: center;
  }

  .programCardPartnerImgContainer {
    position: absolute;
    top: 8px;
    margin-left: 8px;
    background: #FAFCFF;
    padding: 4px;
    width: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
  .programCardPartnerImg {
    max-width: 100%;
    margin-bottom: 3px;
  }

  @media (max-width: 768px) {
    .dialog {
        max-height: 100%;
        height: 100%;
        margin: 0px;
        width: 100%;
        min-height: unset;
        min-width: unset;
        max-width: unset;
      }
  }
  @media (min-width: 768px) {
    .dialogContent{
        width: auto;
        padding: 16px 8px !important;
        text-align: unset;
    }
    .freeVsPaidWrapper {
      display: flex;
      gap: 16px;
      flex-direction: row-reverse;
      width: auto;
      align-self: unset;
    }
    .imageWrapper, .certificateCardImg {
      display: block;
      width: 269px;
      height: 168px;
      margin: auto;
    }
    .programCardPartnerImgContainer {
      /* height: 40px; */
    }
    .salaryHike {
      font-size: 16px;
    }
    .free, .professional {
      font-size: 12px;
      padding: 8px 0px;
    }
    .programName {
      font-size: 18px;
      /* min-height: unset; */
      font-weight: 600;
      min-height: 50px;
      -webkit-line-clamp: 2;
    }
    .freeHike {
      font-size: 16px;
      font-weight: 500;
    }

    .professionalWrapper, .imageWrapper {
      margin: 16px 16px 0px 16px;
    }

    .paidProfessionalWrapper {
      margin: 0px 16px 0px 16px;
    }
    .divider {
      margin: 16px;
    }
    .paidSection {
      margin-top: unset;
      border: 2px solid #EF6C00;
    }
    .paidSection, .freeSection {
      width: 50%;
    }
    .startingAt {
      display: block;
      line-height: 1.6;
      padding-top: 16px;
      .amt {
        margin-left: unset;
      }
    }
    .submitButton{ 
      margin-top: 16px;
      font-size: 16px;
    }
    .programHighLights {
      font-size: 14px;
    }
    .programHighLightsWrapper {
      padding: 8px 16px 24px 16px;
    }
}