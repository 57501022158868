.desktopNavigationContainer {
  width: 22%;
  padding: 32px 56px;
  background-color: var(--primary-60);
  color: var(--white);
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
}
.icon {
  margin-right: 20px;
}
.emailLink {
  text-decoration: underline;
}
.treeRoot {
  margin-bottom: 20px;
}
.treeContent {
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 12px;
}
.parentLabelRoot {
  display: flex;
  align-items: center;
  color: var(--primary-60);
}

.labelRoot {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-left: 4%;
  font-size: 14px;
}
.labelText {
  font-weight: bold;
}

.appBar {
  background-color: var(--white);
}

.logo {
  height: 36px;
  width: auto;
}

.drawerPaper {
  width: 80%;
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  padding: 0 32px 32px 32px;
  height: 100%;
  justify-content: space-between;
}
.drawerCloseIcon {
  display: flex;
  justify-content: flex-end;
}
.logoutIcon {
  filter: invert(100%);
}
.logoutSection {
  display: flex;
  cursor: pointer;
}
.activeLink p {
  font-weight: bold;
}
@media (min-width: 768px) {
  .logo {
    width: 80%;
    flex-shrink: 0;
    height: auto;
  }
  .parentLabelRoot {
    color: var(--white);
  }
  .subTreeContent {
    color: var(--white);
  }
  .emailLink {
    color: var(--white) !important;
  }
  .logoutIcon {
    filter: none;
  }
}
@media (max-width: 768px) {
  .labelRoot {
    font-size: 12px;
  }
}
