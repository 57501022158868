.searchBox {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  border-radius: 4px;
  width: 100%;
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.32);
  height: 40px;
}
.resultBox {
  position: absolute;
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  height: auto;
  overflow: scroll;
  max-height: 480px;
  
}

@media (min-width: 1280px) {
  .resultBox {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px var(--black-14-12), 0 8px 10px 1px var(--black-14);
    border: #908d8d38 solid 1px;
  }

}

.resultsContainer {
  width: 100%;
  position: relative;
  margin-top: 4px;
}

.topContainer {
  margin-top: 8px;
  position: relative;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 8px;
}

.courseTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: #908d8d38 solid 1px;
  padding-bottom: 12px;
}

.contentType {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.moduleItemIcon {
  height: 16px;
  width: 16px;
  padding: 2px !important;
  margin-right: 8px;
}

.courseIcon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.contentHeader {
  display: flex;
  margin-top: 20px;
  margin-bottom: 4px;
}

.searchIcon {
  padding: 4px;
}