  .dialog {
    padding: 24px;
    min-height: 400px;
    height: 92vh;
    max-height: 720px;
    border-radius: 16px;
    /* min-width: 635px; */
    max-width: 678px;
    width: 678px;
  }
  .dialogContent {
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: column;
    margin-top: auto;
    padding: 0px !important;
  }
  .certificateProgramWrapper {
    padding: 8px 0px;
  }
  .certificateProgramHeading {
    font-size: 24px;
    font-weight: 600;
    color: #212121EB;
  }
  .certificateProgramHeadLine {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #212121B8;
    line-height: 1.4;
  }
  .certificateProgramCardWrapper {
    border-radius: 8px;
    border: 1px solid rgba(33, 33, 33, 0.12);
    display: block;
    margin-top: 24px;
  }
  .programCardImg {
    height: 175px;
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  .programName {
    font-size: 18px;
    font-weight: 600;
    color: #212121EB;
  }
  .programHighLightsWrapper {
    padding: 8px 0px;
  }
  .programHighLightsContents {
    svg {
        width: 16px;
    }
    display: flex;
    padding-top: 16px;
  }
  .programHighLights{
    font-size: 14px;
    font-weight: 400;
    color: #212121EB;
    padding-left: 8px;
    align-self: center;
  }
  .certificateProgramCardDetails {
    padding: 16px;
    width: 100%;
  }
  .submitButton {
    font-size: 16px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-top: 16px;
    width: 100%;
    text-transform: capitalize;
}
.continueFreeCourseMobile {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    color: #196AE5;
    width: 100%;
    justify-content: center;
    img {
        filter: invert(34%) sepia(55%) saturate(4693%) hue-rotate(207deg) brightness(94%) contrast(92%);
        margin-bottom: 6px;
    }
}
.continueFreeCourse {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    color: #196AE5;
    width: 100%;
    justify-content: right;
    img {
        filter: invert(34%) sepia(55%) saturate(4693%) hue-rotate(207deg) brightness(94%) contrast(92%);
        margin-bottom: 6px;
    }
    left: 3%;
    display: none;
}
.continueFreeCourse:hover {
    background-color: unset;
    box-shadow: none;
}
.continueFreeCourseMobile:hover {
    background-color: unset;
    box-shadow: none;
}
@media (max-width: 768px) {
  .dialog {
      max-height: 100%;
      height: 100%;
      margin: 0px;
      width: 100%;
    }
}
  @media (min-width: 557px) {
    /* .dialogContent{
        width: 80%;
    } */

    .certificateProgramCardWrapper {
        display: flex;
        height: 270px;
    }
    .certificateProgramCardDetails {
      padding: 24px;
    }
    .programCardImg {
        width: 35%;
        height: auto;
        border-radius: 8px 0px 0px 8px;
    }
    .continueFreeCourse {
        display: flex;
    }
    .continueFreeCourseMobile {
        display: none;
    }
  }