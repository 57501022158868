.container {
  align-items: center;
  padding: 20px;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.start {
  padding: 8px 56px;
}
.later:hover {
  background-color: transparent;
}
.dialogItem {
  text-align: left;
  width: 65px;
  font-weight: bold;
  color: var(--black);
}
.dialogData {
  margin-left: 15px;
}
@media (max-width: 960px) {
  .icon {
    height: 60px;
    width: 60px;
  }
}
