.dialog {
  padding: 16px;
  height: 92vh;
  max-height: 720px;
  max-width: 678px;
  border-radius: 16px;
}
.dialogContent {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding: 0px !important;
  /* padding-left: 10px;
  padding-right: 10px; */
}
.detailsContentWrapper {
  padding: 20px 64px;
}
.alignCenter {
  text-align: center;
}
.heading {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 16px;
  line-height: 24px;
  color: #333333;
}
.preferredProgram {
  font-weight: 500;
  padding-bottom: 8px;
}
.contentWrapper {
  padding-bottom: 8px;
}

.radioGroupWrapper {
  display: flex;
  justify-content: space-between;
}
.radioButton {
  border-radius: 4px;
  padding: 8px 6px;;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 8px;
  border: 1px solid rgba(238, 238, 238, 1);
}

.radioButtonWithBackground {
  background-color: rgba(25, 106, 229, 0.08);
  border-radius: 4px;
  padding: 8px 6px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 8px;
  border: 1px solid rgba(238, 238, 238, 1);
}


.radioBtnHalfWidth {
  width: 48%;
}
.radioBtnFullWidth {
  width: 100%;
}
.radio {
  padding: 0px 4px 0px 0;
}

.submitBtn {
  display: flex;
  align-self: center;
  padding: 8px 80px;
  margin-left: 28px;
  font-size: 16px;
}
.buttonWrapper {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
}
.skipBackBtnWrapper {
  display: none;
  @media (max-width: 960px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
.study_abroad_2-section {
  text-align: center;
}
.mobileBackBtn {
  padding: 0;
  justify-content: left;
}
.showOnlyDesktop {
  display: flex;
  @media (max-width: 960px) {
    display: none;
  }
}
.skipSubmitWrapper {
  width: 100%;
  display: flex;
  justify-content: right;
}
@media (max-width: 960px) {
  .mobileSkip {
    padding: 0;
    min-width: unset;
  }
  .dialog {
    max-height: 100%;
    height: 100%;
    margin: 0px;
    width: 100%;
  }
  .detailsContentWrapper {
    padding: 0px;
  }
  .radioButtonWithBackground,
  .radioButton {
    width: 100%;
  }
  .submitBtn {
    width: 100%;
    margin-left: 0px;
  }
}
