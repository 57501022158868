.roundedIcon {
  border-radius: 50%;
  border: 1px solid var(--grey-40);
  padding: 4px;
}

/** ASSIGNMENT */
.assignmentIcon {
}
.assignmentIcon.coloured {
  color: var(--assignment);
}
.assignmentIcon.coloured.roundedIcon {
  border-color: var(--assignment);
}

/** DISCUSSION TOPIC */
.discussionIcon {
}
.discussionIcon.coloured {
  color: var(--discussion);
}
.discussionIcon.coloured.roundedIcon {
  border-color: var(--discussion);
}

/** QUIZ */
.quizIcon {
}
.quizIcon.coloured {
  color: var(--quiz);
}
.quizIcon.coloured.roundedIcon {
  border-color: var(--quiz);
}

/** FILE */
.resourceIcon {
}
.resourceIcon.coloured {
  color: var(--resource);
}
.resourceIcon.coloured.roundedIcon {
  border-color: var(--resource);
}

/** LIVE VIDEO */
.liveVideoIcon {
}
.liveVideoIcon.coloured {
  color: var(--red-60);
}
.liveVideoIcon.coloured.roundedIcon {
  border-color: var(--red-60);
}

/** SEND */
.sendIcon {
}
.sendIcon.coloured {
  color: var(--send);
}
.sendIcon.coloured.roundedIcon {
  border-color: var(--send);
}

/** SETTINGS */
.settingsIcon {
}
.settingsIcon.coloured {
  color: var(--settings);
}
.settingsIcon.coloured.roundedIcon {
  border-color: var(--settings);
}

/** VIDEO */
.videoIcon {
}
.videoIcon.coloured {
  color: var(--video);
}
.videoIcon.coloured.roundedIcon {
  border-color: var(--video);
}

/** PAGE */
.pageIcon {
}
.pageIcon.coloured {
  fill: var(--page);
}
.pageIcon.coloured.roundedIcon {
  border-color: var(--page);
}

/** LINK */
.linkIcon {
}
.linkIcon.coloured {
  color: var(--link);
}
.linkIcon.coloured.roundedIcon {
  border-color: var(--link);
}

/** OTHERS */
.othersIcon {
}
.othersIcon.coloured {
  color: var(--others);
}
.othersIcon.coloured.roundedIcon {
  border-color: var(--others);
}

.success {
  color: var(--green-60);
}

.error {
  color: var(--red-60);
}

.warning {
  color: var(--yellow-60);
}

.info {
  color: var(--grey-60);
}
