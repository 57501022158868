.academyCertificatePaymentWrapper {
    text-align: center;
    align-self: center;
    padding: 0px;
    padding: 24px;
    p {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
}

.premiumDetailsWrapper {
    text-align: left;
    padding-top: 34px;
}
.premiumFeatureImg {
    width: 24px;
    height: 24px;
}
.pointersWrapper {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.92);
    padding-bottom: 16px;
    span {
        padding-left: 16px;
        font-size: 14px;
        position: relative;
        bottom: 6px;
    }
}

.continueToPayment {
    width: 100%;
    font-size: 16px;
    font-weight: 600 !important;
    margin-top: 16px;
  }

.heading {
    font-size: 20px;
    font-weight: 600;
    padding-right: 8px;
    text-align: left;
}

.certificateAvailable {
    display: flex;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.72);
    b {
        font-size: 28px;

    }
    padding-left: 10px;
}

.dialog {
    min-height: 390px;
    max-height: 480px;
    border-radius: 16px 16px 0px 0px;
    position: absolute;
    bottom: 0;
}

.closePopupDiv {
    height: 24px;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .closePopup {
    font-size: 36px;
    font-weight: 500;
    padding: 10px;
    margin: 10px;
    height: 24px;
    width: 24px;
  }

  .slash {
    text-decoration: line-through;
    font-size: 28px;
    padding-left: 8px;
    color: rgba(33, 33, 33, 0.30);
  }

.gstText {
    color: rgba(0, 0, 0, 0.72);
    font-size: 10px;
    font-weight: 400;
    padding-bottom: 16px;
    text-align: left;
    padding-left: 10px;
}
.discountWrapper {
    border-radius: 99px;
    border: 1px solid #FFB74D;
    background: #FFCC80;
    color: #7A2828;
    font-size: 12px;
    font-weight: 500;
    width: 67px;
    height: 20px;
    padding: 0px 8px;
    align-self: center;
    margin-left: 8px;
}

.asterisk{
    font-size: 12px;
}
.astrik {
    position: relative;
    bottom: 2px;
    padding-right: 2px;
}

@media (min-width: 600px) {
    .dialog {
        max-height: 427px;
    }
}

@media (min-width: 768px) {
    .heading {
        font-size: 28px;
    }
    .pointersWrapper {
        span {
            font-size: 16px;
        }
    }
    .closePopup {
        margin: 20px;
        height: 30px;
        width: 30px;
        background-color: white;
    }
    .dialog {
        width: 100%;
        min-width: 458px;
        border-radius: 16px;
        /* min-height: unset; */
        /* height: 50%; */
        max-height: unset;
        margin: 0;
        position: relative;
    }
}