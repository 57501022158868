.topContainer {
  position: relative;
}

.searchDrawer {
  width: 100%;
}

.drawerContainer {
  padding: 12px;
}
.drawerCloseIcon {
  text-align: right;
}
.inputSearchIcon {
  color: rgba(0, 0, 0, 0.54);
}
.searchBox {
  display: flex;
  justify-content: space-between;
  padding: 4px 12px;
  border-radius: 4px;
  width: 100%;
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.32);
  height: 40px;
}
.resultBox {
  position: absolute;
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  height: auto;
  overflow: scroll;
}
.courseCard {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
}

.courseCardImg {
  width: 24%;
  flex-shrink: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.courseCardName {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.courseCardContent {
  padding: 16px !important;
}

.cardMetaContent {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: var(--grey-60);
  font-size: 12px;
}

.cardMetaContent > *:not(:last-child)::after {
  content: '';
  border: 2px solid var(--grey-60);
  border-radius: 50%;
  margin-right: 4px;
  margin-left: 4px;
  display: inline-block;
  height: 4px;
  width: 4px;
  vertical-align: middle;
}

.cardMetaText {
  font-size: 12px;
}

.courseStatus {
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 500;
}

.pgProgramHeadingWrapper {
  display: flex;
  margin-bottom: 10px;
}

@media (min-width: 1280px) {
  .resultBox {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px var(--black-14-12), 0 8px 10px 1px var(--black-14);
    border: #908d8d38 solid 1px;
    max-height: 75vh;
  }
  .topContainer {
    min-width: 380px;
  }
}
