.glaStrip {
  margin: 20px 0px;
  background-color: var(--grey-40);
  padding: 4px 8px;
  border-left: 4px solid var(--grey-70);
}
.button {
  padding: 0px;
  min-width: 0px;
  padding-bottom: 4px;
}
