.container {
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.title {
  margin-top: 32px;
  margin-bottom: 16px;
}
.subText {
  margin-bottom: 24px;
}

.supportLink {
  color: var(--primary-60) !important;
}
