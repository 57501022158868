.browseCoursesButton {
  text-transform: capitalize;
  font-weight: 400;
  color: var(--black) !important;
  font-size: 16px;
  justify-content: left;
  padding: 0px;
  margin: 8px;
}
.browseCoursesButton:hover {
  background-color: transparent;
}
.popoverBrowseMenu {
  display: flex;
  max-height: 78vh;
}
.flex {
  display: flex;
  align-items: center;
}
.iconWrapper {
  border-radius: 48px;
  background: #FFF3E0;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  text-align: center;
  svg {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 8px;
  }
}
.dividerFreeCourse {
  background: rgba(0, 0, 0, 0.08);
}
.iconWrapperFree {
  border-radius: 48px;
  background: #dae1ff;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  text-align: center;
  svg {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 8px;
  }
}
.headerItem {
  pointer-events: none;
  color: var(--black);
  padding-top: 8px;
  padding-bottom: 8px;
  margin-right: 1px;
  margin-left: 1px;
  background-color: white;
}
.headerItemText {
  font-weight: 700;
}
.listItem {
  padding-top: 4px;
  padding-bottom: 4px;
}
.courseList {
  padding-top: 0;
  width: 300px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  overflow: scroll;
}
.collapsibleDomainName {
  text-transform: capitalize;
  font-weight: 400;
  color: var(--black) !important;
  font-size: 16px;
  justify-content: left;
  padding: 0px;
  margin: 0px;
}
.mobileCourseName {
  margin-bottom: 8px;
}

.programsList {
  width: 320px;
}
/* .programListItem:hover {
  background-color: #edf4ff;
}
.programHeaderItem {
  background-color: #edf4ff;
} */
.programCard {
  background-color: var(--white);
  margin: 10px 0 0 5px;
  padding: 15px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12), 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.programPartnerImg {
  height: 30px;
  margin-right: 5px;
  margin-bottom: 8px;
}
.programInfoText {
  font-size: 12px;
  opacity: 0.8;
  color: #1b1c1d;
}

.courseCategoryType {
  font-size: 14px;
  font-weight: bold;
}

.pgAcademyPremiumIcon {
  margin-right: 5px;
}
