.dialog {
    min-width: 280px;
    min-height: 340px;
    border-radius: 8px;
    max-width: 750px;
    background-color: #e8f0fc;
}

.dialogContent {
    padding: 20px !important;
    display: flex;
    align-items: center;
}

.popupData {
    margin: 12px;
}

.marginTop {
    margin-top: 24px;
}

.exploreBtn {
    margin: 24px auto 0;
    display: block;
}