.profileMenuButton {
  padding: 10px;
  text-transform: unset;
}
.isCorporateUser {
  border: 1px solid var(--grey-30);
  padding: 4px 8px;
}
.companyLogo {
  height: 30px;
  margin-right: 8px;
}
.avatar {
  /* margin-right: 10px; */
}

.userInfo {
  display: flex;
  border-bottom: 1px solid var(--grey-30);
  align-items: center;
  padding: 0px 8px;
}
.userDetails {
  padding: 4px 8px;
}

.menuItem {
  color: var(--black);
  min-height: 32px;
}
.referralAndRewardsMenuItem {
  display: flex;
  justify-content: space-between;
}
.referralAndRewardsText {
  margin-right: 16px;
}
@media (max-width: 960px) {
  .menuItem.oldUi {
    display: none;
  }
}
