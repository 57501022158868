.alertContent {
  background-color: var(--white);
}
@media (max-width: 960px) {
  .alertContent {
    padding: 0px;
  }
  .alertMessage {
    width: 75%;
  }
}

.closeIcon {
  color: var(--grey-60);
  font-size: 20;
}

.messageIcon {
  opacity: 0.9;
  margin-right: 8px;
  font-size: 20;
}

.messageContainer {
  display: flex;
  align-items: center;
}

.message {
  color: var(--black);
}

.success {
  color: var(--green-60);
  border-left: 5px solid var(--green-60);
}

.error {
  color: var(--red-60);
  border-left: 5px solid var(--red-60);
}

.warning {
  color: var(--yellow-60);
  border-left: 5px solid var(--yellow-60);
}

.info {
  color: var(--black);
  border-left: 5px solid var(--grey-60);
}
