.payNowBtn {
  color: white;
  background-color: black;
  width: 111%;
}

.payNowBtnUrgent {
  background-color: #ff3333;
}

.reportProblemIcon {
  margin-right: 10px;
  margin-top: 5px;
}

.payNowBtnHovr:hover {
  background-color: rgb(82, 82, 82);
  border-radius: 4px;
}

.urgentAlert {
  color: #7A2828;
  background-color: #feeaee;
}