
.mouthShutText {
  color: #242224 !important;
  font-size: 15px;
  font-weight: 600;
  margin-left: 3vw;
  margin-top: 8px;
}
.mouthShutAlert {
  background-color: #26A69A !important;
  width: 100%;
}
.mouthShutImage{
  height: 32px;
  margin-left: -6vw;
}
.mouthShutLink {
  margin-left: 15vw;
}
.closeIcon{
  margin-right: 6vw;
}
@media (max-width: 960px) {
  .mouthShutImage {
    margin-left: 1vw;
  }
}