.aiTeacherSnackbarContainer {
  background: linear-gradient(
    131deg,
    rgba(25, 106, 229, 0.16) -38.13%,
    rgba(64, 70, 255, 0.16) 36.98%,
    rgba(255, 51, 51, 0.16) 120%
  );
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(20px);
  color: #1e3e6f;
}

.aiTeacherIcon {
  margin-right: 16px;
}

.tryNowBtn {
  margin-left: 16px;
  border-radius: 8px;
  background: linear-gradient(
    131deg,
    #196ae5 -38.13%,
    #4046ff 36.98%,
    #f33 120%
  );
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
}
