.title {
  color: var(--palette-error-main);
}
.message {
  width: 75%;
  padding: 10px;
}
.link {
  padding: 10px;
}
.emailLink {
  color: var(--palette-primary-main) !important;
}
.helpText {
  color: var(--grey-70);
}
