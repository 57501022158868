.program {
  font-weight: 600;
  font-size: 12px;
  color: var(--primary-60);
}

.dashboardName {
  font-size: 14px;
  font-weight: normal;
  color: #323232;
}

.listDashboardName {
  font-size: 14px;
  font-weight: normal;
  color: var(--black-14-87);
}
.subHeader {
  color: var(--black);
  margin: 8px 0px;
}
.semesterNumber {
  color: var(--grey-60);
  padding-left: 15%;
}
.semesterNumberText {
  font-size: 11px;
  color: var(--grey-60);
  margin-left: 8px;
}
.selectedProgram,
.selectedProgram:focus {
  border: 1px solid var(--grey-40);
  border-radius: 8px;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 32px !important;
}
.selectIcon {
  color: var(--black);
  margin-right: 6px;
}
.programSwitch {
  margin-right: 16px;
  max-width: 160px;
}

.popover {
  top: 56px !important;
  padding: 8px 0;
  border-radius: 8px;
}

@media (max-width: 960px) {
  .popover {
    top: 75px !important;
  }
}
