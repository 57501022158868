.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.title {
  padding: 16px;
}
.icons {
  padding: 24px 0px;
  display: flex;
}
.icon {
  padding: 4px 16px;
  width: 40px;
  height: 40px;
}
.link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
