.actionBtn {
    width: unset;
    margin-left: 48px;
}

.button {
    color: #7a5115;
    border-color: #7a5115;
}

.snackbarLogo {
    margin-right: 15px;
}
  
.subtitleContainer span:not(:first-child):before{
    content:'\00B7';
    padding: 0 5px;
    font-size: 14px;
}

@media (max-width: 600px) {
    .actionBtn {
        margin-top: 14px;
    }
}