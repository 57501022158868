.careerSchoolQueryHeader {
  text-align: center;
  color: #fff;
  padding: 4px 0;
  font-weight: 600;
}

.upgradeLink {
  color: #fff !important;
  text-decoration: underline;
}
.csContainer {
  width: 100%;
  background-color: #000;
}

.enrollButtonHeaderText {
  color: #fff;
  font-size: 10px;
}

.getHelp {
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}

.contactUs {
  display: flex;
  color: #fff;
  margin-top: 4px;
  width: 100%;
  justify-content: center;
}

.contactUsIcon {
  margin-right: 16px;
}

.helpUsIcons {
  text-decoration: none;
  color: #fff !important;
}

.cursorPointer {
  cursor: pointer;
}

.menuTop {
  margin-top: 8px;
}

@media (max-width: 600px) {
  .upgradeLink {
    color: #fff;
    text-decoration: underline;
  }
  .contactInfo {
    color: #fff;
  }
}
