.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.contentWrapper {
  display: block;
  text-align: center;
}
