.content {
  word-break: break-word;
  height: inherit;
}
.content:before {
  content: '';
  display: table;
  clear: both;
}

.content p {
  line-height: 2;
  font-size: 1rem;
  margin: 12px 0;
}
.content li {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 2;
  font-size: 1rem;
}

.content table {
  border-collapse: collapse;
  border: none;
}

.content table td,
.content table th {
  border: 1px solid var(--grey-40);
  padding: 4px 8px;
}

