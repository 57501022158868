.container {
  background: var(--red-60);
  padding: 16px;
  margin: 24px 0;
}
.children {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 4px;
  text-align: center;
}
