.dialog {
  padding-top: 24px;
  min-height: 400px;
  height: 92vh;
  max-height: 720px;
  border-radius: 16px;
  max-width: 678px;
}

.dialogContent {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.select {
  width: 100%;
  margin-top: 20px;
}

.label {
  color: var(--black);
}

.button {
  display: flex;
  align-self: center;
  margin-top: 56px;
  padding: 15px 100px;
}

.listItemRoot {
  white-space: normal;
}

.noOptions {
  color: var(--black)
}

.helperText {
  color: var(--black)
}

.referralDialogContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 80px 32px !important;
}

.referralDialogTitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.5px;
  color: rgba(33, 33, 33, 0.92);
  margin-bottom: 24px;
}

.referralSubTitle {
  margin-bottom: 24px;
}

.referralContentContainer {
  display: flex;
  flex-direction: column;
}

.textField {
  display: block;
  width: 100% !important;
  margin-bottom: 24px;
}

.phoneInputField {
  margin-bottom: 0px;
}

.referralButton {
  margin-top: 24px;
  width: 100%;
}

.referralCancelButton {
  margin-top: 10px;
  width: 100%;
}

.referralHelperTextContent {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
}

.formTimeLimitHeading {
  margin-top: 8px;
}

@media (max-width: 960px) {
  .dialog {
    max-height: 100%;
    height: 100%;
    margin: 0px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .multiLineSelectLabel {
    right: 44px;
    transform: translate(14px, 12px) scale(1);
  }
}

@media (min-width: 769px) {
  .referralDialog {
    min-width: 720px;
  }
}