.academyCertificatePaymentWrapper {
    text-align: center;
    align-self: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
}

.dialog {
    /* min-width: 280px; */
    min-height: 390px;
    max-height: 600px;
    border-radius: 16px 16px 0px 0px;
    /* width: 60%; */
    position: absolute;
    bottom: 0;
}

/* .academyCertificatePayment {
    display: flex;
    gap: 24px;
} */

.continueWithoutCertificate, .continueWithCertificate {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #FFF;
    padding: 16px;
    min-width: 280px;
}

.continueWithCertificate {
    h5 {
        font-size: 18px;
        font-weight: 600;
    }
}
.continueWithoutCertificate {
    display: flex;
    flex-direction: column;
    h5 {
        font-size: 18px;
        font-weight: 600;
    }
    margin-bottom: 16px;
}

.pointersWrapper {
    padding-top: 16px;
    display: flex;
    span {
        font-size: 14px;
        margin-left: 8px;
        margin-top: -2px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.72);
    }
}

.closePopupDiv {
    height: 24px;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .closePopup {
    font-size: 36px;
    font-weight: 500;
    padding: 10px;
    margin: 10px;
    height: 24px;
    width: 24px;
  }
  .continueWithoutCertificateBtn, .continueToPayment {
    width: 100%;
    font-size: 16px;
    font-weight: 600 !important;
  }
  .continueWithoutCertificateBtn {
    border: 1px solid #5E96ED;
    margin-top: 16px;
  }
  .slash {
    text-decoration: line-through;
}

.certificateAvailable {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.72);
    margin-top: 16px;
    b {
        font-size: 16px;
    }
    padding-left: 10px;
}

.gstText {
    color: rgba(0, 0, 0, 0.72);
    font-size: 10px;
    font-weight: 400;
    padding-bottom: 16px;
    text-align: left;
    padding-left: 10px;
}

.asterisk{
    font-size: 12px;
}

@media (min-width: 600px) {
    .dialog {
        max-height: 445px;
    }
    .academyCertificatePayment {
        display: flex;
        gap: 16px;
    }
    .continueWithoutCertificate {
        margin-bottom: 0px;
    }
    .continueWithoutCertificateBtn {
        margin-top: auto;
    }
    .academyCertificatePaymentWrapper {
        p {
            font-size: 24px;
        }
    }
}

@media (min-width: 768px) {
    .certificateAvailable {
        margin-top: 24px;
        /* margin-bottom: 8px; */
    }

    .academyCertificatePaymentWrapper {
        /* margin-bottom: 0px; */
        padding: 20px 0px 50px 0px;
        p {
            font-size: 24px;
        }
    }



    .closePopup {
        margin: 20px;
        height: 30px;
        width: 30px;
        background-color: white;
    }
    .dialog {
        width: 100%;
        min-width: 675px;
        border-radius: 16px;
        /* min-height: unset; */
        /* height: 50%; */
        max-height: unset;
        margin: 0;
        position: relative;
    }

    .academyCertificatePayment {
        display: flex;
        gap: 24px;
    }

    .continueWithoutCertificate {
        margin-bottom: 0px;
        h5 {
            font-size: 16px;
        }
    }
    .continueWithCertificate {
        h5 {
            font-size: 16px;
        }
    }
}