:root {
  --font-size: 16px;
  font-size: var(--font-size);

  /**
   * Header
   */
  --header-height: 64px;
  --snackbar-height: 0px;

  /**
     * Content
     **/
  --content-background: #f9fafb;
  --page-content-margin: 32px;
  --page-content-size: calc(
    100vh - var(--header-height) - var(--footer-height) - 2 *
      var(--page-content-margin)
  );

  /** 
     * Footer 
     **/
  --footer-height: 60px;
  --footer-background: var(--white);
}

@media(max-width: 600px) {
  :root {
    --page-content-margin: 8px;
  }
}

.multiline-limit-body1 {
  /* use this value to count block height */
  line-height: 1.5rem;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3rem;
}

.multiline-limit-body2 {
  /* use this value to count block height */
  line-height: 1.43rem;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 2.5rem;
}

.multiline-limit {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
  opacity: 0.8;
}
/* create the ... */
.multiline-limit:before {
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.multiline-limit:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
img {
  max-width: 100%;
  height: auto;
}

img {
  max-width: 100%;
  height: auto;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: var(--palette-primary-main);
  cursor: pointer;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive::before {
  display: block;
  content: '';
}

input[type='range'] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 40%;
  align-items: center;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  height: 1px;
  cursor: pointer;
  background: var(--palette-primary-main);
  border-radius: 1.3px;
}
input[type='range']::-webkit-slider-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  /* border: 1px solid #000000; */
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background: var(--palette-primary-main);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type='range']::-webkit-slider-runnable-track {
  background: var(--palette-primary-main);
}
input[type='range']::-moz-range-track {
  height: 1px;
  cursor: pointer;
  background: var(--palette-primary-main);
  border-radius: 1.3px;
}
input[type='range']::-moz-range-thumb {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background: var(--palette-primary-main);
  cursor: pointer;
}
input[type='range']::-ms-track {
  height: 1px;
  cursor: pointer;
  background: var(--palette-primary-main);
  border-radius: 1.3px;
}
input[type='range']::-ms-fill-lower {
  background: var(--palette-primary-main);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type='range']::-ms-fill-upper {
  background: var(--palette-primary-main);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type='range']::-ms-thumb {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background: var(--palette-primary-main);
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: var(--palette-primary-main);
}
input[type='range']:focus::-ms-fill-upper {
  background: var(--palette-primary-main);
}
