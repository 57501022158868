.container {
  width: 100%;
  position: unset;
  transform: unset;
  display: flex;
  left: 0px;
  flex: none;
}
.content {
  width: 100%;
  background-color: var(--yellow-50);
  color: var(--black);
  align-content: center;
  display: flex;
}
.message {
  flex: 1;
  justify-content: center;
  display: flex;
}
