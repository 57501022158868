.dialog {
  min-width: 280px;
  min-height: 390px;
  max-height: 530px;
  border-radius: 16px;
  min-width: 790px;
}
.academyReferalDialogContent {
  padding: 0px;
  padding-top: 0px !important;
}
.closePopupDiv {
  height: 24px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.popupChipCont{
  height: 24px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.popupChip{
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 23px;
  height: 20px;
  width: 46px;
  border-radius: 4px;
  display: flex;
  background: #FFD24C;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.4px;
}

.closePopup {
  font-size: 36px;
  font-weight: 500;
  padding: 10px;
  margin: 10px;
  height: 24px;
  width: 24px;
}
.popupPartnerImageCont {
  width: 100%;
}

.popupPartnerImage {
  height: 48px;
}

.popupHeading {
  font-family: sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.18px;
  margin: 10px 0;
}
.popupSubHeading {
  font-family: sans-serif;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 24px;
}
.popupCardCont {
  padding: 10px;
  display: flex;
  margin: 10px;
  height: 500px;
  width: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px 10px 10px 10px;
}
.popupCard {
  max-width: 300px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #fb8c00;
  background: white;
}
.popupCard:hover {
  text-decoration: none;
}
.nonExpPgpCardTop {
  max-width: 300px;
  /* height: 60%; */
  min-height: 120px;
  min-width: 260px;
}
.popupPgpRecommendationImg {
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0px 0px;
}
.popupPgpCardBottom {
  height: 40%;
  text-align: start;
}
.partnerImgContainer {
  background: white;
  position: relative;
  top: -65px;
  border-radius: 8px;
  height: 50px;
  width: 100px;
  padding: 10px 5px;
  margin: 0px 0px 20px 10px;
}
.nonExppgpRecommendationPartnerImg {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.nameCont {
  margin-top: auto;
  color: rgba(0, 0, 0, 0.7);
  padding-top: 12px;
}
.partnerName {
  font-size: 16px;
}

.programName {
  font-size: 16px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  margin-bottom: 0px;
}
.browseContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}
.browseProgramButton {
  font-size: 14px;
  background-color: #196ae5;
  color: white !important;
  border-radius: 4px;
  padding: 12px 48px;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.browseProgramButton img {
  margin-top: -2px;
}
.browseProgramButton:hover {
  text-decoration: none;
}

.dialogSegregationCont {
  display: flex;
}

.popupDataCont {
  width: 50%;
  margin: 15px 10px 0 10px;
}

.popupData {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 15px;
}

.popup_program_details {
  li {
    background: url('../../../common/images/blue-tick.svg') no-repeat left center;
    background-size: 20px 20px;
    padding-left: 30px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .popupData {
    gap: 8px;
  }
  .popupPartnerImage {
    height: 32px;
  }
  .closePopup {
    margin: 20px;
    height: 30px;
    width: 30px;
    background-color: white;
  }
  .popupHeading {
    font-size: 18px;
    line-height: 24px;
  }
  .popupSubHeading, .programName, .partnerName {
    font-size: 14px;
    line-height: 20px;
  }
  .dialogSegregationCont {
    flex-direction: column;
    height: 100%;
  }
  .popupCardCont {
    width: calc(100% - 10px - 10px);
    width: 100%;
    flex-direction: column;
    border-radius: 0;
    height: 40%;
    min-height: 240px;
    position: relative;
    margin: 0;
  }
  .popupDataCont {
    width: auto;
    margin: unset;
    margin-top: 0;
    height: 100%;
    padding: 16px;
  }
  .dialog {
    width: 100%;
    min-width: unset;
    min-height: unset;
    height: 100%;
    max-height: unset;
    margin: 0;
    border-radius: 0;
  }
  .nonExpPgpCardTop {
    min-width: auto;
  }
  .popupCard {
    margin: 0px 30px;
  }
  .popupCtaCont {
    z-index: 10;
    width: calc(100% + 10px + 10px);
    margin-left: -10px;
    padding: 10px;
  }
}

@media (min-width: 769px) {
  .popupCardCont {
    padding: 50px 40px;
  }
  .academyReferalDialogContent {
    overflow: hidden;
  }
}
