.container {
  background-color: var(--footer-background);
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ipContainer {
  display: flex;
  justify-content: center;
  background-color: var(--grey-30);
  padding: 10px;
}
.hideFooter {
  display: none !important;
}
.gridItem {
  text-align: center;
  padding: 8px 4px;
}
.footerLinks {
  display: flex;
  justify-content: center;
}
@media (min-width: 960px) {
  .container {
    padding-right: 80px;
    padding-left: 80px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .gridItem {
    text-align: left;
    padding: 8px 12px;
  }
  .footerLinks {
    justify-content: flex-end;
  }
}
.footerLinks a:hover {
  text-decoration: underline;
}

.footerLink,
.footerLink:hover,
.footerLink:active,
.footerLink:visited {
  color: var(--grey-60) !important;
  text-decoration: none;
}
