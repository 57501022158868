.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 6px 32px;
  background: var(--secondary-40);
  color: white;
  overflow: hidden;
  justify-content: space-between;
  z-index: 1000000;
}

.stopMasqueradeButtonContainer {
  text-align: right;
  margin-left: auto;
}
.stopMasqueradeButton {
  color: white;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

.stopMasqueradeButton:hover {
  border: none;
}

.userDataContainer {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}

.userAvatar {
  height: 100%;
  border-radius: 50%;
  margin-right: 10px;
}
