.container {
  display: flex;
  align-items: center;
  color: var(--black);
  background-color: var(--white);
  flex-direction: column;
}
div.toolbar {
  width: 100%;
}
.hideHeader {
  display: none;
}
.mobileAppBanner {
  width: 100%;
}

@media (min-width: 960px) {
  .mobileAppBanner {
    display: none;
  }
}
