.container {
  background-color: white;
  border: 0px;
  padding: 8px;
  border-left: 5px solid var(--palette-error-main);
  border-radius: 6px;
}
.container button {
  white-space: nowrap;
}
.iconContainer {
  padding: 8px;
}
.errorContainer {
  height: 60px;
  width: 60px;
}
.errorIcon {
  height: 30px;
  width: 30px;
}
@media (max-width: 600px) {
  .container {
    flex-direction: column;
    text-align: center;
    border-left: 0px;
  }
}
