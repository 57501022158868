
  .paidAnnouncementBanner {
    display: flex;
    width: 100%;
    background-color: #D5FF82;
    padding: 8px;
    gap: 8px;
  }
  
  .stickyHeaderText {
    font-size: 12px; 
    line-height: 16px;
    font-weight: 500;
    color: #004131;
  }

  .speaker{
    width: 18px;
    height: 20px;
  }

  @media (min-width: 768px) {
    .paidAnnouncementBanner{
      padding: 12px;
      gap: 10px;
      justify-content: center;
    }

    .stickyHeaderText {
      font-size: 16px; 
      line-height: 20px;
    }

    .speaker{
       width: 22px;
       height: 24px;
    }
  } 
