.dialogContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  overflow: initial;
}

.title {
  color: var(--black);
  margin: 12px 0px;
}
.start {
  margin-top: 12px;
}
.later:hover {
  background-color: transparent;
}
@media (min-width: 960px) {
  .dialogContent {
    padding: 32px;
    padding-top: 32px !important;
  }
  .title {
    margin: 28px 0px;
  }
  .start {
    margin-top: 28px;
  }
}
@media (max-width: 960px) {
  .icon {
    height: 100px;
    width: 100px;
  }
}
