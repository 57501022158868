.mainContainer {
  padding: 16px;
  width: 100%;
}

@media (min-width: 768px) {
  .mainContainer {
    padding: 56px 56px 56px 0;
  }
}
