.container {
  padding: 8px 12px 8px;
}

.header {
  display: flex;
  align-items: center;
}

.title {
  flex: 1;
  font-size: 1.5rem;
}
@media (min-width: 960px) {
  .container {
    padding: 16px 24px 8px;
  }
}
